//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class Documents {
 
  list(document_data){
    return http.get( api.documents.get.endpoint, document_data);
  }

  create(file,params){
    return http.post( api.documents.create.endpoint+params, file ,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      } );
  }
  
  update(params,data){
    return http.put( api.documents.update.endpoint+params, data  );
  }
  
  delete(document_data){
    return http.delete( api.documents.delete.endpoint+document_data );
  }

}

export default new Documents();