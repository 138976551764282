<template>
  <b-row>
    <b-col cols="12">
      <h2>Pagos</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <div class="col-12 mt-14">
      <datatable :items="items" :fields="fields"  @changePerPage="perPageChanged">
        <template slot="filters">
          <b-col cols="2" class="my-3">
            <b-button
              variant="primary"
              class="btn-ghost"
              v-b-modal.modal-create-payment
              style="width: 150px; margin-left: -17px"
              @click="resetForm"
            >
              Añadir nuevo pago
            </b-button>
          </b-col>
        </template>
        <template slot="filters">
          <b-col cols="2" class="my-3">
            <b-button
              variant="primary"
              class="btn-ghost"
              v-b-modal.modal-add-document-admin
            >
              Carga Masiva
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Seleccione departamento"
              label-for="filter-input"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              class="mb-0"
            >
              <b-row>
                <b-col cols="7">
                  <b-form-select
                    size="sm"
                    v-model="documentCreate.department_uuid"
                    :options="loadDepartamentsData"
                    @change="onChangeDepartament"
                  ></b-form-select>
                </b-col>
                <b-button
                  @click="clearSelection"
                  variant="primary"
                  class="btn-ghost mt-2"
                  size="sm"
                >
                  Limpiar
                </b-button>
              </b-row>
            </b-form-group>
          </b-col>
        </template>

        <template slot-scope="{ item }" slot="actions">
          <b-row class="actions-icons">
            <span
              v-if="item.reported_by == null"
              title="reportar"
              @click="reportPayment(item)"
              :id="'_show_reportar_' + item.uuid"
            >
              <i class="iconly-Curved-PaperUpload"></i>
            </span>
            <span
              v-if="item.approved_by == null"
              title="aprobar"
              @click="aprovePayment(item)"
              :id="'_show_aprobar_' + item.uuid"
            >
              <i class="iconly-Light-ShieldDone mr-10"></i>
            </span>
            <span
              v-if="item.approved_by == null && item.reported_by == null"
              title="eliminar"
              @click="showDeleteConfirmation(item.uuid)"
              :id="'_show_eliminar' + item.uuid"
            >
              <i class="iconly-Light-Delete mr-10"></i>
            </span>
          </b-row>

          <b-tooltip :target="'_show_reportar_' + item.uuid" triggers="hover">
            Enviar comprobante
          </b-tooltip>

          <b-tooltip :target="'_show_aprobar_' + item.uuid" triggers="hover">
            Aprobar
          </b-tooltip>

          <b-tooltip :target="'_show_eliminar' + item.uuid" triggers="hover">
            Eliminar
          </b-tooltip>
        </template>

        <template slot="pagination">
          <b-pagination
            align="end"
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          ></b-pagination>
        </template>
      </datatable>
    </div>
    <!-- create document -->
    <b-modal
      size="xl"
      id="modal-add-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-document-admin"
      modal-class="hp-profile-modal-1"
      @hide="modalClose"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir pagos masivos</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Cargar Archivo">
              <b-form-file
                @change="uploadFile"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                ref="file-input"
                style="margin-bottom: 20px"
              ></b-form-file>

              <!-- <input type="file" @change="uploadFile" ref="file">
              <button @click="submitFile">Subir imagen</button> -->
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="submitFile">
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button variant="none" block @click="downloadTemplate()">
              Descargar ejemplo
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
    <!-- create document -->
    <b-modal
      size="xl"
      id="modal-create-payment"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-create-payment"
      modal-class="hp-profile-modal-1"
      @hide="modalClose"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir nuevo recibo de pago</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <!-- <b-col cols="12">
            <b-form-group label="Departamento">
              <v-select
                v-model="paymentData.department_uuid"
                :options="loadDepartamentsData"
                :reduce="(value) => value.code"
              >
              </v-select>
            </b-form-group>
          </b-col> -->
          <b-col cols="12">
            <b-form-group label="Departamento">
              <b-form-select
                v-model="paymentData.departament"
                :options="loadDepartamentsData"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Monto">
              <b-form-input
                v-model="paymentData.amount"
                type="number"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Descripción">
              <b-form-input
                v-model="paymentData.description"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <!-- <b-col cols="12">
            <b-form-group label="Fecha de Vencimiento">
              <imask-input
                class="form-control"
                :value="paymentData.expired_at"
                v-imask="maskDob"
                :unmask="true"
                @complete="onCompleteUntilAt"
                placeholder="día/mes/año"
              />
            </b-form-group>
          </b-col> -->

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="createPayment">
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-create-payment')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- Modal de confirmación de eliminación -->
    <b-modal
      id="modal-delete-confirmation"
      title="eliminar"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-delete-confirmation"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Confirmar eliminación</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <b-row>
        <b-col cols="12">
          <p class="text-center">
            ¿Estás seguro de que deseas eliminar esta visita?
          </p>
        </b-col>

        <b-col cols="6" class="pr-2">
          <b-button variant="danger" block @click="confirmDelete">
            Sí, eliminar
          </b-button>
        </b-col>

        <b-col cols="6" class="pl-2">
          <b-button
            variant="none"
            block
            @click="$bvModal.hide('modal-delete-confirmation')"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- upload payment -->
    <b-modal
      size="xl"
      id="modal-send-payment"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-send-payment"
      modal-class="hp-profile-modal-1"
      @hide="modalClose"
      @shown="loadModalJs"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Enviar pago</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Número de transferencia">
              <b-form-input
                v-model="sendPayment.reported_number"
                type="number"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <div class="container">
              <div class="drag-area">
                <div class="icon">
                  <i class="fas fa-images"></i>
                </div>
                <span class="header">Arrastra y suelta</span>
                <span class="header"
                  >o <span class="button">navega por los archivos</span></span
                >
                <input type="file" hidden />
                <span class="support">soportado: JPEG, JPG, PNG</span>
              </div>
              <div></div>
            </div>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="SendPaymentData">
              Enviar pago
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-send-payment')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormFile,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
  BPagination,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";

import datatable from "@/layouts/components/datatable/Datatable.vue";
import sweet from "@/logic/functions/sweetAlert";
import DragAndDrop from "@/logic/functions/DragAndDrop";
import packages from "@/logic/functions/packages";
import departaments from "@/logic/functions/departaments";
import payments from "@/logic/functions/payments";
import { DateTime } from "luxon";
import { IMaskComponent, IMaskDirective, useIMask } from "vue-imask";
import documents from "@/logic/functions/documents";
import axios from "axios";

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, //this
    BFormFile,
    BPagination,
    BListGroup,
    BListGroupItem,
    datatable,
    "imask-input": IMaskComponent,
  },
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      fields: [
        { key: "info_payemnt_data", label: "Informacion", class: "raw" },
        { key: "info_payemnt_reportado", label: "Reportado", class: "raw" },
        { key: "info_payemnt_aprobado", label: "Aprobado", class: "raw" },
        { key: "actions", label: "Acciones" },
      ],
      maskDob: {
        mask: "00/00/0000",
        lazy: false,
      },
      Actions: [],
      Departaments: [],
      items: [],
      loadNewUsers: [],
      sendPayment: {},
      paymentData: {},
      documentCreate: {},
      loadLastDepartamentsMessages: [],
      loadDepartamentsData: [],
      checkLoadWholesData: [],
      existingUuid: [],
      messageLargeAmountMessage: [],
      chatConfig: {},
      userSendMessage: "",
      userSendMessageUuid: "",
      message: {
        send: "",
        departament: "",
        Searchdepartament: "",
      },
      paginationData: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
      flags: {
        formUpload: false,
        fileUpload: false,
      },
    };
  },
  methods: {
    resetForm() {
      this.paymentData.amount = "";
      this.paymentData.description = "";
    },
    clearSelection() {
      this.documentCreate.department_uuid = "";
      this.departament_filter = this.documentCreate.department_uuid;
      this.listPayments();
    },
    onChangeDepartament() {
      this.departament_filter = this.documentCreate.department_uuid;
      this.listPayments();
    },
    async downloadTemplate() {
      departaments
        .payment_template()
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const filename = "payment_template";
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
          URL.revokeObjectURL(link.href);
          // } catch (error) {
          //   console.error(error);
          // }
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "descarga no completada");
        });
    },
    uploadFile(event) {
      this.documentCreate.file = event.target.files[0];
    },
    modalClose() {
      if (this.flags.fileUpload == true && this.flags.formUpload == false) {
        documents
          .delete({
            params: {
              document_uuid: "" + this.createSales.img + "",
            },
          })
          .then((response) => {})
          .catch((error) => {
            this.flags.fileUpload = false;
            this.createSales.img = "";
          });
      }
    },
    // onCompleteUntilAt(e) {
    //   this.paymentData.expired_at = e.detail._value;
    // },
    loadModalJs() {
      DragAndDrop.upload();
    },
    reportPayment(item) {
      this.sendPayment.payment_uuid = item.uuid;
      this.sendPayment.departament_uuid = item.department_uuid;

      this.$bvModal.show("modal-send-payment");
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deletes(this.itemToDelete);
        this.$bvModal.hide("modal-delete-confirmation");
      }
    },
    showDeleteConfirmation(uuid) {
      this.itemToDelete = uuid;
      this.$bvModal.show("modal-delete-confirmation");
    },
    deletes(uuid) {
      let items = this.items;
      payments
        .delete({
          params: {
            department_payment_uuid: uuid,
          },
        })
        .then((response) => {
          const index = items.findIndex((item) => item.uuid === uuid);
          if (~index) {
            items.splice(index, 1);
          }
          this.listPayments();
          sweet.ToastMsg("", "success", "Eliminado");
        })
        .catch((error) => {});
    },
    async createPayment() {
      await payments
        .create({
          department_uuid: this.paymentData.departament,
          amount: this.paymentData.amount,
          description: this.paymentData.description,
          // expired_at: DateTime.fromFormat(
          //   this.paymentData.expired_at,
          //   "dd/LL/yyyy"
          // ).toFormat("yyyy-LL-dd"),
        })
        .then((response) => {
          sweet.ToastMsg("", "success", "pago creado");
          this.$bvModal.hide("modal-create-payment");
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "pago no creados");
        });
    },
    async SendPaymentData() {
      const file = new FormData();
      let fileData = DragAndDrop.getFile();
      let params = `?department_payment_uuid=${this.sendPayment.payment_uuid}&reported_number=${this.sendPayment.reported_number}`;
      file.append("image", fileData);

      await payments
        .send_payment(file, params)
        .then((response) => {
          this.listPayments();
          this.$bvModal.hide("modal-send-payment");
          sweet.ToastMsg("", "success", "pago enviado");
        })
        .catch((error) => {});
    },
    async aprovePayment(item) {
      await payments
        .approve_payment(item.uuid)
        .then((response) => {
          this.listPayments();
          sweet.ToastMsg("", "success", "pago aprobado");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async listPayments(page = 0, limit = 5, departmentUuid = "") {
      await payments
        .list({
          params: {
            department_uuid: this.departament_filter || departmentUuid,
            limit: this.totalRowsPerpage || limit,
            page: this.currentPage || page,
          },
        })
        .then((response) => {
          this.totalRows = response.data.items;
          this.totalRowsPerpage = response.data.limit;
          this.items = response.data.data;
        })
        .catch((error) => {});
    },
    async loadDepartaments() {
      await departaments
        .list()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.loadDepartamentsData.push({
              text: value.nro,
              value: value.uuid,
            });
          });
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "no se pueden cargar los departamentos");
        });
    },
    async documents(page = 0) {
      await documents
        .list({
          params: {
            limit: 5,
            page: 0,
          },
        })
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {});
    },
    async submitFile() {
      const formData = new FormData();
      formData.append("file", this.documentCreate.file);
      await departaments.upload_excel(formData).then((response) => {
        this.documentCreate.file = null;
        this.documentCreate = {};
        this.$bvModal.hide("modal-add-document-admin");
        sweet.ToastMsg("", "success", "Documento creado");
      });
      // .catch((error) => {
      //   sweet.ToastMsg("", "error", "El archivo no se pudo subir");
      // });
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1;
      this.currentPage = page;
      this.listPayments(page);
    },
    async perPageChanged(perPage) {
      this.totalRowsPerpage = perPage;
      this.currentPage = 0;
      this.listPayments(0, perPage);
    },
  },
  watch: {
    totalRowsPerpage(newValue) {
      console.log(newValue);
    },
  },
  beforeMount() {},
  mounted() {
    this.documents();
    this.loadDepartaments();
    this.listPayments();
  },
};
</script>
<style lang="scss">
.flatpickr-wrapper {
  width: 50% !important;
}
.flatpickr-input[readonly] {
  display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

.drag-area {
  height: 400px;
  border: 3px dashed #e0eafc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px auto;
}
h3 {
  margin-bottom: 20px;
  font-weight: 500;
}
.drag-area .icon {
  font-size: 50px;
  color: #1683ff;
}
.drag-area .header {
  font-size: 20px;
  font-weight: 500;
  color: #34495e;
}
.drag-area .support {
  font-size: 12px;
  color: gray;
  margin: 10px 0 15px 0;
}
.drag-area .button {
  font-size: 20px;
  font-weight: 500;
  color: #1683ff;
  cursor: pointer;
}
.drag-area.active {
  border: 2px solid #1683ff;
}
.drag-area img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.download {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 6px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 3px 1px;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
}

.raw {
  width: 23em;
}

.raw_html_custom {
  padding: 1em;
}

.created_by {
  margin-bottom: -1em;
}

.raw_html_custom > i {
  font-size: 12px;
}

.raw > div:first-child {
  display: grid;
  font-size: 9px;
  line-height: 10px;
  float: inline-start;
}
</style>
