//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class Payments {
 
  list(data){
    return http.get( api.department_payments.get.endpoint, data);
  }

  create(params){
    return http.post( api.department_payments.create.endpoint, params );
  }
  
  delete(params){
    return http.delete( api.department_payments.delete.endpoint,params );
  }

  send_payment(file,params){
    return http.put( api.department_payments.send_payment.endpoint+params, file ,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  approve_payment(params){
    return http.put( api.department_payments.approve_payment.endpoint+'?department_payment_uuid='+params );
  }

}

export default new Payments();