//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class Packages {
 
  list(data){
    return http.get( api.deliverys.get.endpoint, data);
  }

  create(params){
    return http.post( api.deliverys.create.endpoint, params );
  }
  
  update(data,params){
    return http.put( api.deliverys.update.endpoint+params, data );
  }
  
  delete(data){
    return http.delete( api.deliverys.delete.endpoint+data );
  }

}

export default new Packages();